import React, { useEffect, useState } from "react";
import { myLocalStorage } from "../../../components/StorageHelper";
import { Autocomplete, Box, IconButton, Stack, TextField } from "@mui/material";
import { ArrowForwardIos, ArrowBackIos } from "@mui/icons-material";
import BloodHoundQueries from "./BloodHoundQueries";
import axios from "axios";
import { NGROK } from "../../../APIs";
import GraphComponent from "./GraphComponent";
import useUserStore from "../../../services/userStore";

const ADAnalytics = () => {
  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;
  const [queryData, setQueryData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const userData = useUserStore((state) => state.user);

  const [tenantList, setTenantList] = useState([]);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const selectedQuery = async (matchquery) => {
    setLoading(true);
    try {
      const response = await axios.post(`${NGROK}/api/bloodhound/query/run`, {
        cypherQuery: matchquery?.query,
        includeProperties: matchquery?.includeProperties,
      });
      if (response?.data) {
        setQueryData(response?.data);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };

  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  return (
    <Stack>
      <Box display="flex" justifyContent="flex-start">
        <Stack
          direction={"row"}
          spacing={2}
          sx={{
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Stack direction={"row"} spacing={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              {tenantList?.length > 0 ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={selectedTenantName ? selectedTenantName : " "}
                  options={tenantList.map((tenant) => tenant.tenantName)}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Tenant List" />
                  )}
                  onChange={(e, value) => {
                    handleTenantChange(value);
                  }}
                />
              ) : null}
            </Box>
          </Stack>
        </Stack>
      </Box>

      <Box>
        <Box display="flex" height="100%">
          {!expanded && (
            <Box
              sx={{
                width: "10%",
                transition: "width 0.3s ease",
                height: "70vh",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <Box display="flex" alignItems="center" height="100%">
                <IconButton
                  onClick={toggleExpanded}
                  sx={{
                    zIndex: 1,
                    border: "1px solid gray",
                  }}
                >
                  <ArrowForwardIos />
                </IconButton>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              width: expanded ? "30%" : "0",
              transition: "width 0.3s ease",
              height: "100%",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Box display="flex" alignItems="center" height="100%">
              <IconButton
                onClick={toggleExpanded}
                sx={{
                  position: "absolute",
                  top: "50%",
                  right: expanded ? "-16px" : "-32px",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                  backgroundColor: "white",
                  border: "1px solid gray",
                }}
              >
                {expanded ? <ArrowBackIos /> : <ArrowForwardIos />}
              </IconButton>
              <Box p={2} sx={{ visibility: expanded ? "visible" : "hidden" }}>
                <BloodHoundQueries
                  tenantName={latestTenant}
                  selectedQuery={selectedQuery}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              transition: "all 0.3s ease",
              width: expanded ? "calc(100% - 30%)" : "100%",
              height: "65vh",
              padding: 2,
            }}
          >
            <GraphComponent
              graphData={queryData}
              loading={loading}
              setExpanded={setExpanded}
            />
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

export default ADAnalytics;
